<template>
  <div id="message-container" :class="{ 'd-none': !counts?.length || auth.storeSelectRequired }">
    <div id="message-fab" @click="isOpen = !isOpen">
      <Icon :icon="isOpen ? 'ic:close' : 'ic:round-chat'" class="w-100 h-100" />
    </div>
    <div id="message-window" :class="{ open: isOpen }">
      <div class="h-100 d-flex flex-column p-2">
        <div id="header" class="ps-2">
          <div v-if="selectedDriver" class="d-flex mb-2">
            <div id="back-button" class="pointer me-3" @click="selectedDriver = null">
              <Icon :icon="'ic:chevron-left'" class="fs-4" />
            </div>
            <h5 class="mb-0">{{ selectedDriver.name }}</h5>
          </div>
        </div>
        <div class="flex-fill border" :class="{ 'message-list': !!selectedDriver }" style="background-color: whitesmoke; overflow-y: auto">
          <div v-if="loading" class="h-100 w-100 d-flex align-items-center justify-content-center">
            <div class="spinner-border" />
          </div>
          <MessageRow v-else-if="selectedDriver" v-for="msg in messageHistory" :key="msg.messageId" :message="msg" />
          <DriverMessageRow v-else v-for="count in counts" :key="count.driverId" :count="count" @selected="driverSelected(count)" />
        </div>
        <div v-if="selectedDriver" class="mt-2 d-flex align-items-center">
          <input type="text" ref="messageTextInput" v-model="messageText" @keypress="messageKeypress" class="form-control flex-fill" style="max-width: unset" />
          <div id="send-button" @click="sendMessage">
            <Icon :icon="'ic:send'" class="w-100 h-100" />
          </div>
        </div>
      </div>
    </div>
    <div id="message-badge" v-if="totalUnread" class="text-center">
      <strong>{{ totalUnread }}</strong>
    </div>
  </div>
</template>

<script setup lang="ts">
interface SelectedDriver {
  id: number
  name: string
}

import { computed, nextTick, onMounted, ref, watch } from "vue"
import { HubConnectionBuilder, HubConnection, IHttpConnectionOptions, LogLevel } from "@microsoft/signalr"

import messages, { Message, MessageCount } from "@/api/messages"
import DriverMessageRow from "./DriverMessageRow.vue"
import MessageRow from "./MessageRow.vue"
import { useAuthStore } from "@/stores/auth"

const auth = useAuthStore()
const isAuthenticated = computed(() => auth.isAuthenticated())
let signalr: HubConnection | null = null

const isOpen = ref(false)
const loading = ref(false)
const selectedDriver = ref(null as SelectedDriver | null)

const counts = ref(null as MessageCount[] | null)

const messageText = ref("")
const messageTextInput = ref(null as HTMLElement | null)

const messageHistory = ref([] as Message[])

onMounted(refreshCounts)
connectSignalR()

async function refreshCounts(showLoading: boolean = true) {
  if (!isAuthenticated.value) return
  loading.value = showLoading
  counts.value = await messages.getMessageCounts()
  counts.value.sort((a, b) => {
    if (a.unreadMessages > 0 != b.unreadMessages > 0) return a.unreadMessages > 0 ? -1 : 1
    if (a.lastMessageTime && b.lastMessageTime) return b.lastMessageTime.getTime() - a.lastMessageTime.getTime()
    if (a.lastMessageTime) return -1
    else if (b.lastMessageTime) return 1
    return a.driverName.localeCompare(b.driverName)
  })
  loading.value = false
}
watch(isAuthenticated, () => {
  connectSignalR()
  refreshCounts()
})

const totalUnread = computed(() => {
  if (counts.value) {
    return counts.value.reduce((a, b) => a + b.unreadMessages, 0)
  }
  return 0
})

async function driverSelected(count: MessageCount) {
  selectedDriver.value = {
    id: count.driverId,
    name: count.driverName
  }
  nextTick(() => {
    const input = messageTextInput.value
    if (!input) return
    input.focus()
  })

  loading.value = true
  messageHistory.value = await messages.getMessages(count.driverId)
  messageHistory.value.sort((a, b) => b.sentTime.getTime() - a.sentTime.getTime())
  loading.value = false

  setTimeout(async () => {
    if (selectedDriver.value?.id != count.driverId) return
    for (const msg of messageHistory.value.filter((m) => m.fromDriver && !m.isRead)) {
      await messages.setAsRead(msg.messageId)
    }
    await refreshCounts(false)
  }, 2000)
}

function messageKeypress(ev: KeyboardEvent) {
  if (ev.code == "Enter") sendMessage()
}
async function sendMessage() {
  const text = messageText.value
  if (!text) return
  const driverId = selectedDriver.value?.id
  if (!driverId) return
  const newMessage = {
    fromDriver: false,
    hasAttachment: false,
    isDelivered: false,
    isRead: false,
    sentTime: new Date(),
    messageId: "tmp" + new Date().getTime().toString(),
    messageText: text,
    userName: auth.userInfo?.name
  }
  messageHistory.value.splice(0, 0, newMessage)
  messageText.value = ""
  const id = await messages.sendMessage(driverId, text)
  const index = messageHistory.value.findIndex((m) => m.messageId == newMessage.messageId)

  messageHistory.value.splice(index, 1, {
    ...newMessage,
    messageId: id
  })
}

async function connectSignalR() {
  if (signalr != null) {
    await signalr.stop()
    signalr = null
  }
  if (!isAuthenticated.value) return
  const hubOptions: IHttpConnectionOptions = {
    accessTokenFactory: () => auth.getAccessToken() ?? ""
  }
  const url = "/api/web/MessagingHub"
  signalr = new HubConnectionBuilder().withUrl(url, hubOptions).configureLogging(LogLevel.Error).build()
  signalr.on("MessageDelivered", (msgId) => {
    const index = messageHistory.value.findIndex((m) => m.messageId == msgId)
    if (index >= 0) {
      const msg = messageHistory.value[index]
      messageHistory.value.splice(index, 1, { ...msg, isDelivered: true })
    }
  })
  signalr.on("MessageRead", (msgId) => {
    const index = messageHistory.value.findIndex((m) => m.messageId == msgId)
    if (index >= 0) {
      const msg = messageHistory.value[index]
      messageHistory.value.splice(index, 1, { ...msg, isRead: true })
    }
  })
  signalr.on("MessageReceived", (msgId, driverId, msg) => {
    if (selectedDriver.value?.id == driverId) {
      messageHistory.value.splice(0, 0, {
        messageId: msgId,
        messageText: msg,
        fromDriver: true,
        sentTime: new Date(),
        hasAttachment: false,
        isDelivered: true,
        isRead: true
      })
      setTimeout(async () => {
        if (selectedDriver.value?.id != driverId) return
        await messages.setAsRead(msgId)
      }, 2000)
    } else refreshCounts(false)
  })
  await signalr.start()
}

watch(isOpen, (open) => {
  if (!open) {
    selectedDriver.value = null
    messageHistory.value = []
  }
})
</script>

<style scoped>
#message-container {
  z-index: 2;
  position: fixed;
  right: 15px;
}

#message-fab {
  z-index: 3;
  height: 40px;
  width: 40px;
  box-shadow: 2px 2px 8px #aaa;
  border-radius: 100%;
  cursor: pointer;
  background-color: rgba(23, 185, 233, 1);
  padding: 8px;
  color: #f5f5f5;
  transition: background-color 0.25s;
  position: relative;
}
#message-fab:hover {
  background-color: rgba(43, 205, 253);
}

#message-window {
  z-index: 2;
  position: absolute;
  width: 400px;
  height: 75vh;
  right: 0px;
  top: 45px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 15px;
  box-shadow: 5px 4px 18px #bbb;
  transition:
    top 0.35s,
    right 0.35s,
    width 0.35s,
    height 0.35s;
}
#message-window:not(.open) {
  height: 0 !important;
  width: 0 !important;
  top: 20px;
  right: 20px;
  display: none;
}

#message-badge {
  z-index: 4;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  color: white;
  border-radius: 100%;
  top: -4px;
  right: -4px;
  font-size: x-small;
}

#back-button {
  border-radius: 100%;
  transition: background-color 0.3s;
}

#back-button:hover {
  background-color: #eee;
}

.message-list {
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  justify-content: end;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

#send-button {
  border-radius: 6px;
  transition: background-color 0.3s;
  background-color: rgb(64, 152, 224);
  color: white;
  padding: 0.2rem;
  padding-left: 0.4rem;
  margin-left: 0.5rem;
  min-width: 32px !important;
  width: 32px !important;
  height: 32px;
  cursor: pointer;
}

#send-button:hover {
  background-color: rgba(64, 152, 224, 0.7);
}
</style>
