import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "h-100 d-flex flex-column p-2" }
const _hoisted_2 = {
  id: "header",
  class: "ps-2"
}
const _hoisted_3 = {
  key: 0,
  class: "d-flex mb-2"
}
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = {
  key: 0,
  class: "h-100 w-100 d-flex align-items-center justify-content-center"
}
const _hoisted_6 = {
  key: 0,
  class: "mt-2 d-flex align-items-center"
}
const _hoisted_7 = {
  key: 0,
  id: "message-badge",
  class: "text-center"
}

import { computed, nextTick, onMounted, ref, watch } from "vue"
import { HubConnectionBuilder, HubConnection, IHttpConnectionOptions, LogLevel } from "@microsoft/signalr"

import messages, { Message, MessageCount } from "@/api/messages"
import DriverMessageRow from "./DriverMessageRow.vue"
import MessageRow from "./MessageRow.vue"
import { useAuthStore } from "@/stores/auth"

interface SelectedDriver {
  id: number
  name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MessagingPopup',
  setup(__props) {

const auth = useAuthStore()
const isAuthenticated = computed(() => auth.isAuthenticated())
let signalr: HubConnection | null = null

const isOpen = ref(false)
const loading = ref(false)
const selectedDriver = ref(null as SelectedDriver | null)

const counts = ref(null as MessageCount[] | null)

const messageText = ref("")
const messageTextInput = ref(null as HTMLElement | null)

const messageHistory = ref([] as Message[])

onMounted(refreshCounts)
connectSignalR()

async function refreshCounts(showLoading: boolean = true) {
  if (!isAuthenticated.value) return
  loading.value = showLoading
  counts.value = await messages.getMessageCounts()
  counts.value.sort((a, b) => {
    if (a.unreadMessages > 0 != b.unreadMessages > 0) return a.unreadMessages > 0 ? -1 : 1
    if (a.lastMessageTime && b.lastMessageTime) return b.lastMessageTime.getTime() - a.lastMessageTime.getTime()
    if (a.lastMessageTime) return -1
    else if (b.lastMessageTime) return 1
    return a.driverName.localeCompare(b.driverName)
  })
  loading.value = false
}
watch(isAuthenticated, () => {
  connectSignalR()
  refreshCounts()
})

const totalUnread = computed(() => {
  if (counts.value) {
    return counts.value.reduce((a, b) => a + b.unreadMessages, 0)
  }
  return 0
})

async function driverSelected(count: MessageCount) {
  selectedDriver.value = {
    id: count.driverId,
    name: count.driverName
  }
  nextTick(() => {
    const input = messageTextInput.value
    if (!input) return
    input.focus()
  })

  loading.value = true
  messageHistory.value = await messages.getMessages(count.driverId)
  messageHistory.value.sort((a, b) => b.sentTime.getTime() - a.sentTime.getTime())
  loading.value = false

  setTimeout(async () => {
    if (selectedDriver.value?.id != count.driverId) return
    for (const msg of messageHistory.value.filter((m) => m.fromDriver && !m.isRead)) {
      await messages.setAsRead(msg.messageId)
    }
    await refreshCounts(false)
  }, 2000)
}

function messageKeypress(ev: KeyboardEvent) {
  if (ev.code == "Enter") sendMessage()
}
async function sendMessage() {
  const text = messageText.value
  if (!text) return
  const driverId = selectedDriver.value?.id
  if (!driverId) return
  const newMessage = {
    fromDriver: false,
    hasAttachment: false,
    isDelivered: false,
    isRead: false,
    sentTime: new Date(),
    messageId: "tmp" + new Date().getTime().toString(),
    messageText: text,
    userName: auth.userInfo?.name
  }
  messageHistory.value.splice(0, 0, newMessage)
  messageText.value = ""
  const id = await messages.sendMessage(driverId, text)
  const index = messageHistory.value.findIndex((m) => m.messageId == newMessage.messageId)

  messageHistory.value.splice(index, 1, {
    ...newMessage,
    messageId: id
  })
}

async function connectSignalR() {
  if (signalr != null) {
    await signalr.stop()
    signalr = null
  }
  if (!isAuthenticated.value) return
  const hubOptions: IHttpConnectionOptions = {
    accessTokenFactory: () => auth.getAccessToken() ?? ""
  }
  const url = "/api/web/MessagingHub"
  signalr = new HubConnectionBuilder().withUrl(url, hubOptions).configureLogging(LogLevel.Error).build()
  signalr.on("MessageDelivered", (msgId) => {
    const index = messageHistory.value.findIndex((m) => m.messageId == msgId)
    if (index >= 0) {
      const msg = messageHistory.value[index]
      messageHistory.value.splice(index, 1, { ...msg, isDelivered: true })
    }
  })
  signalr.on("MessageRead", (msgId) => {
    const index = messageHistory.value.findIndex((m) => m.messageId == msgId)
    if (index >= 0) {
      const msg = messageHistory.value[index]
      messageHistory.value.splice(index, 1, { ...msg, isRead: true })
    }
  })
  signalr.on("MessageReceived", (msgId, driverId, msg) => {
    if (selectedDriver.value?.id == driverId) {
      messageHistory.value.splice(0, 0, {
        messageId: msgId,
        messageText: msg,
        fromDriver: true,
        sentTime: new Date(),
        hasAttachment: false,
        isDelivered: true,
        isRead: true
      })
      setTimeout(async () => {
        if (selectedDriver.value?.id != driverId) return
        await messages.setAsRead(msgId)
      }, 2000)
    } else refreshCounts(false)
  })
  await signalr.start()
}

watch(isOpen, (open) => {
  if (!open) {
    selectedDriver.value = null
    messageHistory.value = []
  }
})

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: "message-container",
    class: _normalizeClass({ 'd-none': !counts.value?.length || _unref(auth).storeSelectRequired })
  }, [
    _createElementVNode("div", {
      id: "message-fab",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
    }, [
      _createVNode(_component_Icon, {
        icon: isOpen.value ? 'ic:close' : 'ic:round-chat',
        class: "w-100 h-100"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", {
      id: "message-window",
      class: _normalizeClass({ open: isOpen.value })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (selectedDriver.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  id: "back-button",
                  class: "pointer me-3",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedDriver.value = null))
                }, [
                  _createVNode(_component_Icon, {
                    icon: 'ic:chevron-left',
                    class: "fs-4"
                  })
                ]),
                _createElementVNode("h5", _hoisted_4, _toDisplayString(selectedDriver.value.name), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex-fill border", { 'message-list': !!selectedDriver.value }]),
          style: {"background-color":"whitesmoke","overflow-y":"auto"}
        }, [
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "spinner-border" }, null, -1)
              ])))
            : (selectedDriver.value)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(messageHistory.value, (msg) => {
                  return (_openBlock(), _createBlock(MessageRow, {
                    key: msg.messageId,
                    message: msg
                  }, null, 8, ["message"]))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(counts.value, (count) => {
                  return (_openBlock(), _createBlock(DriverMessageRow, {
                    key: count.driverId,
                    count: count,
                    onSelected: ($event: any) => (driverSelected(count))
                  }, null, 8, ["count", "onSelected"]))
                }), 128))
        ], 2),
        (selectedDriver.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                ref_key: "messageTextInput",
                ref: messageTextInput,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((messageText).value = $event)),
                onKeypress: messageKeypress,
                class: "form-control flex-fill",
                style: {"max-width":"unset"}
              }, null, 544), [
                [_vModelText, messageText.value]
              ]),
              _createElementVNode("div", {
                id: "send-button",
                onClick: sendMessage
              }, [
                _createVNode(_component_Icon, {
                  icon: 'ic:send',
                  class: "w-100 h-100"
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (totalUnread.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("strong", null, _toDisplayString(totalUnread.value), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})