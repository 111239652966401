/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import { Icon } from "@iconify/vue"

import PrimeVue from "primevue/config"
import Aura from "@primevue/themes/aura"
import DataTable from "primevue/datatable"
import Column from "primevue/column"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./assets/css/site.css"

const pinia = createPinia()

const app = createApp(App)
app.use(pinia)
app.use(router)

app.use(PrimeVue, {
  theme: {
    preset: Aura
  }
})
app.component("PVDataTable", DataTable)
app.component("PVColumn", Column)

app.component("Icon", Icon)

app.mount("#app")
